import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import Homepage from "./pages/homepage";
// import About from "./pages/about";
// import Projects from "./pages/projects";
import Experience from "./pages/experience";
import Publications from "./pages/publications";
import PDFViewer from "./pages/pdfReader";
import Contact from "./pages/contact";
import Notfound from "./pages/404";

import { TRACKING_ID } from "./data/tracking";
import "./app.css";

function App() {
	useEffect(() => {
		if (TRACKING_ID !== "") {
			ReactGA.initialize(TRACKING_ID);
		}
	}, []);

	return (
		<div className="App">
			<Routes>
				<Route path="/" element={<Homepage />} />
				{/* <Route path="/about" element={<About />} /> */}
				{/* <Route path="/projects" element={<Projects />} /> */}
				<Route path="/experience" element={<Experience />} />
				<Route path="/publications" element={<Publications />} />
				<Route path="/publications/:slug" element={<PDFViewer />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="*" element={<Notfound />} />
			</Routes>
		</div>
	);
}

export default App;
